<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <p id="lotQrcode" v-bind="attrs" v-on="on" @click="detail"></p>
      </template>
      <v-card style="position:relative;">
        <v-form
          ref="entryForm"
          @submit.prevent="generatev2"
          style="position: relative; padding:15px 10px;"
        >
          <v-card
            flat
            style="text-align:center;margin-bottom:20px;position:relative;"
          >
            <v-toolbar-title class="overline">
              <p
                class="pqrcode"
                style="font-size: 20px; margin: 0; font-weight: bold;"
              >
                Generate QRCode
              </p>
            </v-toolbar-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="drawer = !drawer"
                  style="position:absolute; right:0; top:-5px;"
                  icon
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <span>Pengaturan</span>
            </v-tooltip>
          </v-card>
          <div>
            <v-row no-gutters style="padding: 5px 10px; ">
              <v-col cols="12" style="padding: 15px; min-height:200px;">
                <vue-html2pdf
                  shrink-to-page
                  :show-layout="true"
                  :float-layout="false"
                  :enable-download="false"
                  :preview-modal="false"
                  :paginate-elements-by-height="1400"
                  filename="qrcode"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a6"
                  :pdf-content-width="templateSetting().width"
                  @beforeDownload="beforeDownload($event)"
                  ref="html2Pdf"
                  :html-to-pdf-options="{
                    margin: templateSetting().margin,
                    html2canvas: {
                      scale: 5,
                      width:
                        templateSetting().paperSize[0] *
                        templateSetting().canvasScale[0],
                      height:
                        templateSetting().paperSize[1] *
                        templateSetting().canvasScale[1]
                    },
                    jsPDF: {
                      orientation:
                        this.param.labelSize.id === 0 ||
                        this.param.labelSize.id === 3
                          ? 'l'
                          : 'p',
                      unit: 'mm',
                      format: templateSetting().paperSize
                    }
                  }"
                  style="display:flex; justify-content:center; align-items:center;"
                >
                  <!-- class="d-flex justify-center align-center" -->
                  <section slot="pdf-content" style="margin:auto;">
                    <!-- PDF Content Here -->
                    <div
                      id="qrCode"
                      ref="generatedQrCodeLot"
                      v-if="selectedLot !== null"
                      :style="
                        `padding: ${templateSetting().padding}; height:${
                          templateSetting().height
                        }; width:${
                          templateSetting().width
                        }; margin:auto;transition:0.3s;font-family:'Arial';`
                      "
                    >
                      <div
                        v-if="templateSetting().orientation === 'landscape'"
                        style="position:relative;top:4px;"
                      >
                        <!-- Type 0 -->
                        <div
                          v-if="templateSetting().type === 0"
                          style="position:relative; left:-7px;"
                        >
                          <v-col
                            cols="12"
                            style="padding:3px;text-align:center;position:relative; left:10px;"
                          >
                            <p
                              :style="
                                `
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;`
                              "
                            >
                              {{
                                selectedLot.company_name.indexOf('(') === -1
                                  ? selectedLot.company_name
                                  : selectedLot.company_name.slice(
                                      0,
                                      selectedLot.company_name.indexOf('(')
                                    )
                              }}
                            </p>
                          </v-col>
                          <div
                            style="position:relative;top:-5px; left:5px; display:flex;"
                          >
                            <!-- class="d-flex" -->
                            <v-col cols="4" style="padding: 5px;margin:auto;">
                              <div
                                :style="
                                  `width:${templateSetting().boxSize +
                                    'px'}; height:${templateSetting().boxSize +
                                    'px'};transition:0.3s;`
                                "
                              >
                                <!-- :text=`${getLinkAddress}/asset-management/aset-kepemilikan/lot/detail/${selectedLot.lot_no}` before update -->
                                <qr-code
                                  v-if="boxCode"
                                  :text="`LOT_DETAIL/${selectedLot.lot_no}`"
                                  :size="templateSetting().boxSize"
                                  error-level="L"
                                ></qr-code>
                              </div>
                            </v-col>
                            <v-col
                              cols="8"
                              style="padding: 5px;position:relative; left:0px; top:0px;"
                            >
                              <v-row no-gutters style="padding:0;">
                                <v-col cols="12" style="margin-top:0px;">
                                  <div
                                    style="display:flex; justify-content:flex-start; align-items:flex-start; border-bottom:0.5px solid black;"
                                  >
                                    <!-- class="d-flex justify-start align-start" -->
                                    <div
                                      :style="
                                        `
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%; display:flex; justify-content:space-between; align-items:flex-start;`
                                      "
                                    >
                                      <!-- class="d-flex justify-space-between align-start" -->
                                      <span style="margin-right:10px;"
                                        >Kode</span
                                      >
                                    </div>
                                    <div
                                      :style="
                                        `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:80%;font-weight: bold;`
                                      "
                                    >
                                      :&nbsp; {{ selectedLot.lot_no }}
                                    </div>
                                  </div>
                                </v-col>
                                <v-col cols="12" style="margin-top:5px;">
                                  <div
                                    style="display:flex; justify-content:flex-start; overflow:hidden; border-bottom:0.5px solid black;"
                                  >
                                    <!-- class="d-flex justify-content-start " -->
                                    <div
                                      :style="
                                        `display:flex; justify-content:space-between; align-items:flex-start;
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%;`
                                      "
                                    >
                                      <!-- class="d-flex justify-space-between align-start" -->
                                      <span style="margin-right:10px;"
                                        >Nama</span
                                      >
                                    </div>
                                    <div
                                      :style="
                                        `display:flex;
                        font-size: ${
                          templateSetting().fontSize
                        }; font-weight:bold;width:80%; height:30px; overflow:hidden;`
                                      "
                                    >
                                      <!-- class="d-flex" -->
                                      <span>:</span>
                                      <span style="color:transparent;">.</span>
                                      <div v-html="`${selectedLot.name}`"></div>
                                    </div>
                                  </div>
                                </v-col>
                                <v-col cols="12" style="margin-top:5px;">
                                  <div
                                    style="display:flex; justify-content:flex-start; align-items:flex-start;"
                                  >
                                    <!-- class="d-flex justify-start align-start"  -->
                                    <div
                                      :style="
                                        `
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%; display:flex; justify-content:space-between; align-items:flex-start;`
                                      "
                                    >
                                      <!-- class="d-flex justify-space-between align-start" -->
                                      <span style="margin-right:10px;"
                                        >Dept</span
                                      >
                                    </div>
                                    <div
                                      :style="
                                        `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:80%;font-weight: bold;`
                                      "
                                    >
                                      :&nbsp;
                                      {{
                                        selectedLot.department_name !== null
                                          ? selectedLot.department_name.toUpperCase()
                                          : '-'
                                      }}
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </div>
                        </div>
                        <div
                          v-if="templateSetting().type === 3"
                          style="position:relative; top:-3px; left:7px;"
                        >
                          <div
                            :style="
                              `
                        font-size: ${
                          templateSetting().fontSize
                        }; font-weight: 900;text-align:center;`
                            "
                          >
                            {{
                              selectedLot.company_name.indexOf('(') === -1
                                ? selectedLot.company_name
                                : selectedLot.company_name.slice(
                                    0,
                                    selectedLot.company_name.indexOf('(')
                                  )
                            }}
                          </div>
                          <div
                            style="display:flex; position:relative;top:-6px; left:0px;"
                          >
                            <!-- class="d-flex" -->
                            <v-col cols="6" style="padding: 5px;margin:auto;">
                              <div
                                :style="
                                  `width:${templateSetting().boxSize +
                                    'px'}; height:${templateSetting().boxSize +
                                    'px'};transition:0.3s;position:relative; top:0px;`
                                "
                              >
                                <qr-code
                                  v-if="boxCode"
                                  :text="`LOT_DETAIL/${selectedLot.lot_no}`"
                                  :size="templateSetting().boxSize"
                                  error-level="L"
                                ></qr-code>
                              </div>
                            </v-col>
                            <v-col
                              cols="6"
                              style="padding: 5px;position:relative; left:-5px; top:0px;"
                            >
                              <v-row no-gutters style="padding:0;"
                                ><v-col cols="12" style="margin-top:2px;">
                                  <div
                                    style="display:flex; flex-direction: column; justify-content:flex-start; align-items:flex-start; border-bottom:0.5px solid black;"
                                  >
                                    <!-- class="d-flex flex-column justify-start align-start" -->
                                    <div
                                      :style="
                                        `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:85px;font-weight: 900;`
                                      "
                                    >
                                      {{ selectedLot.lot_no }}
                                    </div>
                                  </div>
                                </v-col>
                                <v-col cols="12" style="margin-top:2px;">
                                  <div
                                    style="overflow:hidden;
                                border-bottom:0.5px solid black; display:flex; justify-content:flex-start;"
                                  >
                                    <!-- class="d-flex justify-content-start " -->
                                    <div
                                      v-html="`${selectedLot.name}`"
                                      :style="
                                        `display:flex;
                        font-size: ${
                          templateSetting().fontSize
                        }; font-weight:bold;width:85px; height:47px; overflow:hidden; overflow-wrap: break-word;`
                                      "
                                    ></div>
                                  </div>
                                </v-col>
                                <v-col cols="12" style="margin-top:2px;">
                                  <div
                                    style="display:flex; justify-content:flex-start; align-items:flex-start;"
                                  >
                                    <!-- class="d-flex justify-start align-start" -->
                                    <div
                                      :style="
                                        `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:85px;font-weight: bold;position:relative; top:-1px;`
                                      "
                                    >
                                      {{
                                        selectedLot.department_name !== null
                                          ? selectedLot.department_name.toUpperCase()
                                          : '-'
                                      }}
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-col>
                          </div>
                        </div>
                      </div>

                      <div v-if="templateSetting().orientation === 'portrait'">
                        <div
                          v-if="templateSetting().type === 2"
                          style="position:relative; left:-5px; top:10px;"
                        >
                          <v-col
                            cols="12"
                            style="padding: 0; text-align:center;"
                          >
                            <p
                              :style="
                                `
                        margin: 0;
                        font-size: 1.3vw;
                        font-weight: bold;`
                              "
                            >
                              {{
                                selectedLot.company_name.indexOf('(') === -1
                                  ? selectedLot.company_name
                                  : selectedLot.company_name.slice(
                                      0,
                                      selectedLot.company_name.indexOf('(')
                                    )
                              }}
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0; margin-top:10px;">
                            <div
                              :style="
                                `width:${templateSetting().boxSize +
                                  'px'}; height:${templateSetting().boxSize +
                                  'px'};margin:auto;transition:0.3s;`
                              "
                            >
                              <qr-code
                                v-if="boxCode"
                                :text="`LOT_DETAIL/${selectedLot.lot_no}`"
                                :size="templateSetting().boxSize"
                                error-level="L"
                              ></qr-code>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding: 5px 15px; margin-top:10px;"
                          >
                            <div
                              style="display:flex; justify-content:flex-start; align-items:flex-start; border-bottom:0.5px solid black;"
                            >
                              <!-- class="d-flex justify-start align-start" -->
                              <div
                                :style="
                                  `
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%; display:flex; justify-content:space-between; align-items:flex-start;`
                                "
                              >
                                <!-- class="d-flex justify-space-between align-start" -->
                                <span style="margin-right:10px;">Kode</span>
                              </div>
                              <div
                                :style="
                                  `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:80%;font-weight: bold;`
                                "
                              >
                                :&nbsp; {{ selectedLot.lot_no }}
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding: 5px 15px;">
                            <div
                              style="overflow:hidden; border-bottom:0.5px solid black; display:flex; justify-content:flex-start;"
                            >
                              <!-- class="d-flex justify-content-start " -->
                              <div
                                :style="
                                  `display:flex; justify-content:flex-start; align-items:flex-start;
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%;`
                                "
                              >
                                <!-- class="d-flex justify-space-between align-start" -->
                                <span style="margin-right:10px;">Nama</span>
                              </div>
                              <div
                                :style="
                                  `
                        font-size: ${
                          templateSetting().fontSize
                        }; font-weight:bold;width:80%; height:70px; overflow:hidden; display:flex;`
                                "
                              >
                                <!-- class="d-flex" -->
                                <!-- height:50px; -->
                                <span>:</span>
                                <span style="color:transparent;">.</span>
                                <div v-html="`${selectedLot.name}`"></div>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding: 5px 15px;">
                            <div
                              style="display:flex; justify-content:flex-start; align-items:flex-start;"
                            >
                              <!-- class="d-flex justify-start align-start"  -->
                              <div
                                :style="
                                  `display:flex; justify-content:space-between; align-items:flex-start;
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%;`
                                "
                              >
                                <!-- class="d-flex justify-space-between align-start" -->
                                <span style="margin-right:10px;">Dept</span>
                              </div>
                              <div
                                :style="
                                  `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:80%;font-weight: bold;`
                                "
                              >
                                :&nbsp;
                                {{
                                  selectedLot.department_name !== null
                                    ? selectedLot.department_name.toUpperCase()
                                    : '-'
                                }}
                              </div>
                            </div>
                          </v-col>
                        </div>
                        <div
                          v-if="templateSetting().type === 1"
                          style="position:relative; left:-5px; top:-10px;"
                        >
                          <!-- left:-5px; top:5px; -->
                          <v-col
                            cols="12"
                            style="padding: 0; text-align:center;position:relative; top:5px;"
                          >
                            <p
                              :style="
                                `
                        margin: 0;
                        font-size: 1vw;
                        font-weight: bold;`
                              "
                            >
                              {{
                                selectedLot.company_name.indexOf('(') === -1
                                  ? selectedLot.company_name
                                  : selectedLot.company_name.slice(
                                      0,
                                      selectedLot.company_name.indexOf('(')
                                    )
                              }}
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0; margin-top:10px;">
                            <div
                              :style="
                                `width:${templateSetting().boxSize +
                                  'px'}; height:${templateSetting().boxSize +
                                  'px'};margin:auto;transition:0.3s;`
                              "
                            >
                              <qr-code
                                v-if="boxCode"
                                :text="`LOT_DETAIL/${selectedLot.lot_no}`"
                                :size="templateSetting().boxSize"
                                error-level="L"
                              ></qr-code>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            style="padding: 2px 15px 2px 10px; margin-top:5px;"
                          >
                            <div
                              style="border-bottom:0.5px solid black; display:flex; justify-content:flex-start; align-items:flex-start;"
                            >
                              <!-- class="d-flex justify-start align-start" -->
                              <div
                                :style="
                                  `display:flex; justify-content:space-between; align-items:flex-start;
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%;`
                                "
                              >
                                <!-- class="d-flex justify-space-between align-start" -->
                                <span style="margin-right:10px;">Kode</span>
                              </div>
                              <div
                                :style="
                                  `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:80%;font-weight: bold;`
                                "
                              >
                                :&nbsp; {{ selectedLot.lot_no }}
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding: 2px 15px 2px 10px;">
                            <div
                              style="overflow:hidden; border-bottom:0.5px solid black; display:flex; justify-content:flex-start;"
                            >
                              <!-- class="d-flex justify-content-start " -->
                              <div
                                :style="
                                  `display:flex; justify-content:space-between; align-items:flex-start;
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%;`
                                "
                              >
                                <!-- class="d-flex justify-space-between align-start" -->
                                <span style="margin-right:10px;">Nama</span>
                              </div>
                              <div
                                :style="
                                  `display:flex;
                        font-size: ${
                          templateSetting().fontSize
                        }; font-weight:bold;width:80%; height:52px; overflow:hidden;`
                                "
                              >
                                <!-- class="d-flex" -->
                                <!-- height:50px; -->
                                <span>:</span>
                                <span style="color:transparent;">.</span>
                                <div v-html="`${selectedLot.name}`"></div>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" style="padding: 2px 15px 2px 10px;">
                            <div
                              style="display:flex; justify-content:flex-start; align-items:flex-start;"
                            >
                              <!-- class="d-flex justify-start align-start"  -->
                              <div
                                :style="
                                  `display:flex; justify-content:space-between; align-items:flex-start;
                        font-size: ${templateSetting().fontSize};
                        font-weight: bold; width:20%;`
                                "
                              >
                                <!-- class="d-flex justify-space-between align-start" -->
                                <span style="margin-right:10px;">Dept</span>
                              </div>
                              <div
                                :style="
                                  `
                        font-size: ${
                          templateSetting().fontSize
                        }; width:80%;font-weight: bold;`
                                "
                              >
                                :&nbsp;
                                {{
                                  selectedLot.department_name !== null
                                    ? selectedLot.department_name.toUpperCase()
                                    : '-'
                                }}
                              </div>
                            </div>
                          </v-col>
                        </div>
                      </div>
                    </div>
                  </section>
                </vue-html2pdf>
              </v-col>
            </v-row>
          </div>
          <div
            flat
            style="text-align:center;"
            class="d-flex flex-column justify-center align-center"
          >
            <v-btn
              style="width:95%;"
              rounded
              elevation="1"
              color="primary"
              class="white--text font-weight-bold ma-2"
              type="submit"
            >
              generate
            </v-btn>

            <v-btn
              style="width:95%;"
              rounded
              elevation="1"
              color="error"
              class="white--text font-weight-bold ma-2"
              @click="close"
            >
              Batal
            </v-btn>
          </div>
        </v-form>

        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-printer-settings</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Pengaturan dokumen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item>
              <v-list-item-content
                ><v-select
                  v-model="param.paperSize"
                  label="Ukuran kertas"
                  :items="['a4', 'a6', 'letter']"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  clearable
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content
                ><v-select
                  v-model="param.labelSize"
                  label="Ukuran label"
                  :items="[
                    {
                      name: 'X-Small (30mm x 60mm)',
                      id: 3
                    },
                    {
                      name: 'Small (80mm x 60mm)',
                      id: 0
                    },
                    {
                      name: 'Mid (110mm x 80mm)',
                      id: 1
                    },
                    {
                      name: 'Big (148mm x 105mm)',
                      id: 2
                    }
                  ]"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  clearable
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../../services/buildType'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  props: ['selectedLot'],
  components: {
    VueHtml2pdf
    // barcode: VueBarcode
  },
  data() {
    return {
      asset: buildType.apiURL('asset'),
      dialog: false,
      boxCode: true,
      param: {
        paperSize: 'a6',
        labelSize: {
          name: 'Besar (148mm x 105mm)',
          id: 2
        }
      },
      drawer: null,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' }
      ],
      printerSettings: {
        small: [60, 30],
        normal: [104, 50.8],
        medium: [80, 100],
        big: [105, 148]
      },
      canvasSize: {
        small: 3.1,
        normal: 2.7,
        medium: 2.8,
        big: 2.8
      }
    }
  },
  computed: {
    ...mapGetters(['getLinkAddress'])
  },
  watch: {
    'param.labelSize'() {
      this.boxCode = false
      setTimeout(() => {
        this.boxCode = true
      }, 300)
    }
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    generatev2() {
      this.$refs.html2Pdf.generatePdf()
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          window.open(pdf.output('bloburl'))
        })
    },
    templateSetting() {
      // big
      if (this.param.labelSize.id === 2) {
        const label = {
          type: 2,
          orientation: 'portrait',
          height: '419px',
          width: '297px',
          fontSize: '16px',
          boxSize: 150,
          padding: '10.583px',
          labelWidth: '60px',
          dotWidth: '20',
          valueWidth: '180px',
          margin: [0, 0, 1, 1],
          labelSize: '148mm x 105mm',
          barcode: {
            width: 1,
            height: 50,
            conWidth: '245px',
            conHeight: '90px',
            posLeft: '-2px',
            posTop: '0'
          },
          paperSize: [105, 148],
          canvasScale: [2.8, 2.8]
        }
        return label
      }
      // medium
      if (this.param.labelSize.id === 1) {
        const label = {
          type: 1,
          orientation: 'portrait',
          height: '311.5px',
          width: '226.5px',
          fontSize: '12px',
          boxSize: 110,
          padding: '8px',
          labelWidth: '60px',
          dotWidth: '10',
          valueWidth: '120px',
          margin: [3, 3, 2, 2],
          labelSize: '110mm x 80mm',
          barcode: {
            width: 1,
            height: 35,
            conWidth: '180px',
            conHeight: '80px',
            posLeft: '0',
            posTop: '-5px'
          },
          paperSize: [80, 100],
          canvasScale: [2.7, 2.7]
        }
        return label
      }
      // small
      if (this.param.labelSize.id === 0) {
        const label = {
          type: 0,
          orientation: 'landscape',
          width: '283.5px',
          height: '156px',
          fontSize: '10px',
          boxSize: 80,
          padding: '5px',
          labelWidth: '40px',
          dotWidth: '10',
          valueWidth: '100px',
          margin: [2, 2.5, 1.5, 1.5],
          labelSize: '100mm x 55mm',
          barcode: {
            width: 1,
            height: 40,
            conWidth: '0',
            conHeight: '0',
            posLeft: '0',
            posTop: '0'
          },
          paperSize: [104, 50.8],
          canvasScale: [2.7, 2.5]
        }
        return label
      }
      // x-small
      if (this.param.labelSize.id === 3) {
        const label = {
          type: 3,
          orientation: 'landscape',
          width: '170px',
          height: '85px',
          fontSize: '8px',
          boxSize: 70,
          padding: '0px',
          labelWidth: '40px',
          dotWidth: '10',
          valueWidth: '100px',
          margin: [0.5, 1, 0, 0],
          labelSize: '100mm x 55mm',
          barcode: {
            width: 1,
            height: 40,
            conWidth: '0',
            conHeight: '0',
            posLeft: '0',
            posTop: '0'
          },
          paperSize: [60, 30],
          canvasScale: [3.1, 3.1]
        }
        return label
      }
    },
    detail() {
      console.log(this.selectedLot)
    },
    close() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
// @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;1,300&display=swap');
// #qrCode {
//   font-family: 'Ubuntu', sans-serif;
// }
</style>
